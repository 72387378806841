import * as React from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import PourPosition from '../../../assets/pourPositionLg.png';
import TruckPosition from '../../../assets/truckPositionLg.png';

const render = (status) => {
  return <h1>{status}</h1>;
};

export default  ({ticket}) => {
  const [zoom, setZoom] = React.useState(15); // initial zoom
  const [center, setCenter] = React.useState({
    lat: 55,
    lng: 42,
  });

  const { beginPourLatitude, beginPourLongitude, currentLatitude, currentLongitude } = ticket || {};
  
  const onIdle = (m) => {
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
  };

  const mapCoordinates = {
    lat: beginPourLatitude ?? currentLatitude,
    lng: beginPourLongitude ?? currentLongitude,
  }

  return (
    <div style={{ display: "flex", height: "100%" }}>
      {
        mapCoordinates.lat && mapCoordinates.lng && (
          <Wrapper apiKey={"AIzaSyDdtBKqz3I7vXUWZa708t4M0eNYVVl5-W0"} render={render}>
            <Map
              center={mapCoordinates}
              onIdle={onIdle}
              zoom={zoom}
              style={{ flexGrow: "1", height: "100%" }}
            >
              {currentLatitude && currentLongitude &&
                <Marker key="go" position={{lat: currentLatitude, lng: currentLongitude}} icon={TruckPosition} />
              }
              {beginPourLatitude && beginPourLongitude &&
                <Marker key="go" position={{ lat: beginPourLatitude, lng: beginPourLongitude }} icon={PourPosition} />
              }
            </Map>
          </Wrapper>
        )
      }
    </div>
  );
};

const Map = ({
  onIdle,
  children,
  style,
  ...options
}) => {
  const ref = React.useRef(null);
  const [map, setMap] = React.useState();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {...options}));
    }
  }, [ref, map, options]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const Marker = (options) => {
  const [marker, setMarker] = React.useState();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};