import { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useForm } from "react-hook-form";
import Button from '@material-ui/core/Button';
import { format } from "date-fns";
import UserContext from "../UserContext";
import Account from "./selects/Account";
import Jobs from "./selects/Jobs";
import Orders from "./selects/Orders";
import Ticket from "./selects/Ticket";
import Metrics from "../components/Metrics";

const useStyles = makeStyles(
  ({ spacing, palette: { primary }, typography: { pxToRem } }) => {
    return {
      main: {
        height: "100vh",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
      },
      flex: {
        display: "flex",
        alignItems: "center"
      },
      container: {
        height: "100vh",
        width: "100%",
      },
      set1: {
        position: "fixed",
        top: "40%",
        transform: "translate(0, -50%)",
        width: "100%",
        padding: spacing(2)
      },
      set2: {
        position: "fixed",
        top: "80%",
        textAlign: "center",
        width: "100%",
      },
      block: {
        display: "block",
        "&:first-of-type": {
          marginBottom: spacing(4),
        },
      },
      wide: {
        width: "100%",
        "&:last-of-type": {
          marginTop: spacing(4)
        }
      }
    };
  },
);

export default () => {

  const classes = useStyles();
  const history = useHistory();
  const { control } = useForm();
  const parsedTicket = JSON.parse(sessionStorage.getItem("currentTicket"));
  const [ticket, setTicket] = useState(parsedTicket || undefined);
  const handleTicket = (ticket, order) => {
    setTicket(ticket);
    sessionStorage.setItem("currentTicket", JSON.stringify(ticket));
    history.push({
      pathname: '/ticket',
      search: `?ticketId=${ticket.ticketId}`
    });
  }
  const view = () => {
    const path = location.pathname;
    switch (path) {
      case "/":
        return "jobs";
      case "/order":
        return "order";
      case "/ticket":
        return "ticket";
      default:
        return "jobs";
    }
  };

  const {
    email,
    accounts,
    updateEmail,
    updatePw,
    jobs,
    updateJobs,
    selectedJob,
    updateAccounts,
    selectedAccount,
    pw,
    updateSelectedAccount,
    updateSelectedJob,
    updateSelectedOrder
  } = useContext(UserContext);

  const location = useLocation();

  // we're using this to prevent a refresh loop in a useeffect.
  // is there a better way? I'm sure. You figure it out.
  const [localAccount, setLocalAccount] = useState(selectedAccount);

  const returnHeaders = useCallback(() => {
    let theseHeaders = new Headers();
    theseHeaders.append("Content-Type", "application/json");
    theseHeaders.append("verifi-user", email);
    theseHeaders.append("verifi-user-pass", pw);
    return theseHeaders;
  }, [email, pw]);

  const xhrDate = format(new Date(), "yyyyMMdd");

  const reduceArray = (arr) => arr.reduce((total, current) => {
    const ids = total.map((item) => item.id);
    if (!ids.includes(current.id)) {
      total.push(current);
      return total;
    } else {
      return total;
    }
  }, []);

  useEffect(() => {
    var myHeaders = returnHeaders();
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    if (accounts.length === 0) {
      fetch(`https://${process.env.REACT_APP_BACKEND_HOST_NAME}/rs/api/2.0/account/all`, requestOptions)
        .then(response => response.json())
        .then(result => {
          updateAccounts(result);
          if (result.length > 0) {
            updateSelectedAccount(localStorage.getItem("selectedAccount") || result[0].id);
            if (localStorage.getItem("selectedAccount")) {
              setLocalAccount(undefined);
            }
          }
        })
        .catch(error => {
          console.log('error', error);
        });
    }
  }, [accounts.length, returnHeaders, updateAccounts, updateSelectedAccount]);

  useEffect(() => {
    var myHeaders = returnHeaders();
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    if (selectedAccount && selectedAccount !== localAccount) {
      fetch(`https://${process.env.REACT_APP_BACKEND_HOST_NAME}/rs/api/2.0/job?accountId=${selectedAccount}&dayYYYYMMDD=${xhrDate}`, requestOptions)
        .then(response => response.json())
        .then(result => updateJobs(reduceArray(result).sort((a, b) => (a.name > b.name) ? 1 : -1)))
        .then(setLocalAccount(selectedAccount))
        .catch(error => {
          console.log('error', error);
        });
    }
  }, [localAccount, returnHeaders, selectedAccount, updateJobs, xhrDate]);

  return (
    <div className={classes.container}>
        {view() === "jobs" && <Metrics control={control} />}
        {view() === "jobs" && <Account accounts={accounts} />}
        {view() === "jobs" && <Jobs jobs={jobs} />}
        {view() === "order" && <Orders handleTicket={handleTicket} />}
        {view() === "ticket" && <Ticket ticket={ticket} />}
        <Button
          color="secondary"
          size="large"
          variant="contained"
          type="submit"
          onClick={() => {
            updateEmail(undefined);
            localStorage.removeItem("id");
            updatePw(undefined);
            localStorage.removeItem("word");
            localStorage.removeItem("selectedAccount");
            updateAccounts([]);
            updateJobs([]);
            updateSelectedAccount(undefined);
            updateSelectedJob(undefined);
            updateSelectedOrder(undefined);
            history.push('/login');
          }}
          className={classes.wide}
        >
          LOG OUT
        </Button>
    </div >
  )
};
