import { createContext } from "react";


export default createContext({
  email: undefined,
  updateEmail: () => {},
  pw: undefined,
  updatePw: () => {},
  accounts: [],
  updateAccounts: () => {},
  selectedAccount: undefined,
  updateSelectedAccount: () => {},
  selectedJob: undefined,
  updateSelectedJob: () => {},
  selectedOrder: undefined,
  updateSelectedOrder: () => {},
  selectedTicket: undefined,
  updateSelectedTicket: () => {},
  metric: "1",
  updateMetric: () => {},
  jobs: [],
  updateJobs: () => {},
});