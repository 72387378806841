import { useContext, useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import UserContext from "../../UserContext";
import MapSection from "./map/OrderMap";
import LowerTable from "./orders/LowerTable";

const useStyles = makeStyles(
  ({ spacing, typography: { pxToRem } }) => {
    return {
      root: {
        width: '100%',
        paddingLeft: spacing(1),
        paddingRight: spacing(2),
      },
      info: {
        display: "flex",
        marginTop: spacing(2),
        alignItems: "center",
        "& div": {
          textAlign: "center",
          width: "95%",
          "&:first-of-type": {
            width: "5%",
          }
        }
      },
      back: {
        fontSize: pxToRem(40),
        fontWeight: "bold",
        cursor: "pointer"
      },
      heading: {
        padding: spacing(2),
        paddingBottom: 0,
      },
      specs: {
        display: "grid",
        gridTemplateColumns: "25% 25% 25% 25%",
        gridTemplateRows: "auto",
        "& div": {
          flex: "1 0 21%",
          margin: spacing(1),
          "&:nth-child(odd)": {
            fontWeight: "bold"
          }
        },
      },
      center: {
        marginTop: spacing(2)
      },
      adjust: {
        marginTop: spacing(2),
        overflowX: "scroll",
        display: "flex",
      },
      gray: {
        color: "#aaa",
      },
      column: {
        width: "16.6%"
      },
      flexy: {
        marginTop: spacing(2),
        display: "flex",
      },
      addpad: {
        marginTop: spacing(2),
        borderRight: "1px solid #ccc",
        "& td, & th": {
          paddingRight: spacing(1.5),
          paddingLeft: spacing(1.5)
        }
      },
      offset: {
        "& td, & th": {
          paddingLeft: spacing(1.5),
          paddingRight: spacing(1.5)
        }
      },
      normal: {
        width: "100vw",
        "& div": {
          width: "100vw",
        },
      },
      controlled: {
        "& td, & th": {
          fontSize: "2.6vw"
        }
      }
    };
  },
);

export const convertMm = (val, metric = "1", append = true) => {
  if (!val) return "--"
  if (metric === "1") {
    return `${Math.round(val)}${append ? "mm" : ""}`;
  } else {
    return `${(Math.round((val * 0.03937) * 4) / 4).toFixed(2)}${append ? "in" : ""}`;
  }
};

export const convertMmCeil = (val, metric = "1", append = true) => {
  if (!val) return "--"
  if (metric === "1") {
    return `${Math.ceil(val/5) * 5}${append ? "mm" : ""}`;
  } else {
    return `${(Math.ceil((val * 0.03937) * 4) / 4).toFixed(2)}${append ? "in" : ""}`;
  }
};
export const convertDegC = (val, metric = "1") => {
  if (!val) return "--"
  if (metric === "1") {
    return `${Math.round(val)}\u00b0C`;
  } else {
    return `${Math.round((val * (9/5)) + 32)}\u00b0F`;
  }
};
export const convertM3 = (val, metric = "1", append = true) => {
  if (!val) return "--";
  if (metric === "1") {
    return `${Math.round(val * 10)/10}${append ? "m\u00B3" : ""}`;
  } else {
    return `${Math.round((val * 1.308) * 10)/10}${append ? "yd\u00B3" : ""}`;
  }
}
export const convertL = (val, metric, append = true) => {
  if (!val || val < 0) return "--";
  if (metric === "1") {
    return `${(Math.round(val * 10)/10).toFixed(1)}${append ? "l" : ""}`;
  } else {
    return `${(Math.round((val / 3.785) * 10)/10).toFixed(1)}${append ? "gal" : ""}`;
  }
}

export const convertMl = (val, metric, append = true) => {
  if (!val || val < 0) return "--";
  if (metric === "1") {
    return `${(Math.round(val * 10)/10).toFixed(1)}${append ? "ml/m\u00B3" : ""}`;
  } else {
    return `${(Math.round((val / 38.681) * 10)/10).toFixed(1)}${append ? "oz/yd\u00B3" : ""}`;
  }
}

export const cleanString = (string) => {
  const words = string.split("_");
  const final = words.map((itm) => itm[0].toUpperCase() + itm.substr(1).toLowerCase()
  );
  return final.join(" ");
}

export default ({ handleTicket }) => {
  const classes = useStyles();
  const history = useHistory();
  const search = new URLSearchParams(window.location.search);
  const id = search.get('id');
  const { jobs, metric, email, pw } = useContext(UserContext);
  const [data, setData] = useState(jobs.find((item) => item.id === parseInt(id)));
  const [avgWait, setAvgWait] = useState(undefined);
  const [avgSlump, setAvgSlump] = useState(undefined);
  const [avgRevs, setAvgRevs] = useState(undefined);
  const [avgWaterAdded, setAvgWaterAdded] = useState(undefined);
  const [avgWaterRemaining, setAvgWaterRemaining] = useState(undefined);
  const [avgArrivalAge, setAvgArrivalAge] = useState(undefined);
  const [avgPourTime, setAvgPourTime] = useState(undefined);
  const [tickets, setTickets] = useState(undefined);
  const [needsRefresh, setNeedsRefresh] = useState(true);
  const isXs = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  useEffect(() => {
    setData(jobs.find((item) => item.id === parseInt(id)))
  }, [id, jobs])

  const returnHeaders = useCallback(() => {
    let theseHeaders = new Headers();
    theseHeaders.append("Content-Type", "application/json");
    theseHeaders.append("verifi-user", email);
    theseHeaders.append("verifi-user-pass", pw);
    return theseHeaders;
  }, [email, pw]);

  useEffect(() => {
    var myHeaders = returnHeaders();
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    if (id && needsRefresh) {
      fetch(`https://${process.env.REACT_APP_BACKEND_HOST_NAME}/rs/api/2.0/job/deliveries?jobId=${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setTickets(result.sort((a, b) => (a.arrivalTimeUTCSeconds > b.arrivalTimeUTCSeconds) ? 1 : -1))
        })
        .then(setNeedsRefresh(false))
        .catch(error => {
          console.log('error', error);
        });
    }
  }, [id, returnHeaders, needsRefresh]);

  useEffect(() => {
    const hasAvgWait = tickets?.filter((itm) => itm.arrivalTimeUTCSeconds && itm.dischargeTimeUTCSeconds);
    const hasSlump = tickets?.filter((itm) => itm.currentSlumpMm ?? itm.currentSlumpAtDischargeMm);
    const hasRevs = tickets?.filter((itm) => itm.totalRevsAtDischarge ?? itm.totalRevs);
    const hasWaterAdded = tickets?.filter((itm) => {
      const hasDischargeProp = itm.hasOwnProperty("waterAddedAtDischargeL");
      const hasCurrentProp = itm.hasOwnProperty("waterAddedL");
      if (hasDischargeProp && itm.waterAddedAtDischargeL >= 0) {
        return itm;
      }
      if (hasCurrentProp && itm.waterAddedL >= 0) {
        return itm;
      }
      return false;
    });
    const hasWaterRemaining = tickets?.filter((itm) => {
      const hasDischargeProp = itm.hasOwnProperty("waterRemainingAtDischargeL");
      const hasCurrentProp = itm.hasOwnProperty("waterRemainingL");
      if (hasDischargeProp && itm.waterRemainingAtDischargeL >= 0) {
        return itm;
      }
      if (hasCurrentProp && itm.waterRemainingL >= 0) {
        return itm;
      }
      return false;
    });
    const waitTimes = hasAvgWait && hasAvgWait.map((itm) => itm.dischargeTimeUTCSeconds - itm.arrivalTimeUTCSeconds);
    const slump = hasSlump && hasSlump.length > 0 &&
      hasSlump.map((itm) => itm.currentSlumpMm ?? itm.currentSlumpAtDischargeMm)
      .reduce((a, b) => a + b) / hasSlump.length;
    const revs = hasRevs && hasRevs.length > 0 && Math.round(hasRevs
      .map((itm) => itm.totalRevsAtDischarge ?? itm.totalRevs)
      .reduce((a, b) => a + b) / hasRevs.length);
    const waterAdded = hasWaterAdded && hasWaterAdded.length > 0 && hasWaterAdded
      .map(itm => itm.waterAddedAtDischargeL ?? itm.waterAddedL).reduce((a, b) => a + b)/ hasWaterAdded.length;
    const waterRemaining = hasWaterRemaining && hasWaterRemaining.length > 0 && hasWaterRemaining
      .map(itm => itm.waterRemainingAtDischargeL ?? itm.waterRemainingL).reduce((a, b) => a + b)/ hasWaterRemaining.length;
    const toMinutes = waitTimes && waitTimes.length > 0 && Math.round((waitTimes && waitTimes.reduce((a, b) => a + b) / hasAvgWait.length) / 60);
    const ageMinutes = tickets?.filter((itm) => itm.arrivalTimeUTCSeconds && itm.loadingTimeUTCSeconds)
      .map((itm) => itm.arrivalTimeUTCSeconds - itm.loadingTimeUTCSeconds);
    const ageMinutesAvg = ageMinutes && ageMinutes.length > 0 && Math.round((ageMinutes.reduce((a, b) => a + b) / ageMinutes.length) / 60);
    const pourTime = tickets?.filter((itm) => itm.arrivalTimeUTCSeconds && itm.dischargeTimeUTCSeconds)
      .map((itm) => itm.dischargeTimeUTCSeconds - itm.arrivalTimeUTCSeconds);
    const pourTimeAvg = pourTime && pourTime.length > 0 && Math.round((pourTime.reduce((a, b) => a + b) / pourTime.length) / 60);
    waitTimes && hasAvgWait.length > 0 ? setAvgWait(toMinutes) : setAvgWait("--");
    slump && setAvgSlump(slump);
    revs && setAvgRevs(revs);
    waterAdded && setAvgWaterAdded(waterAdded);
    waterRemaining && setAvgWaterRemaining(waterRemaining);
    ageMinutesAvg && setAvgArrivalAge(ageMinutesAvg);
    pourTimeAvg && setAvgPourTime(pourTimeAvg);
  }, [tickets]);

  // const handleChange = (e) => {
  //   setData(jobs.find((item) => item.id === e.target.value));
  //   history.push({
  //     search: `?id=${e.target.value}`
  //   })
  //   setId(e.target.value);
  //   setNeedsRefresh(true);
  // };

  const handleBack = () => (
    history.push({
      pathname: '/',
    })
  );

  return (
    <>
      <div className={classes.root}>
        <div className={classes.info}>
          <div className={classes.back} onClick={handleBack}>
            &lt;
          </div>
          <div>
            <Typography variant="h2">{data?.contractor?.name}</Typography>
            <Typography variant="h3">{`Order ${data?.orderNumber}`}</Typography>
          </div>
        </div>
      </div>
      <div>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow className={isXs ? classes.controlled : ""}>
                <TableCell>MIX<br />DESIGN</TableCell>
                <TableCell>{`${metric === "1" ? "CM" : "CY"}`}<br />POURED</TableCell>
                <TableCell>POUR<br />RATE{`${metric === "1" ? " CM" : " CY"}`}</TableCell>
                <TableCell>AVG<br />WAIT TIME</TableCell>
                <TableCell>AVG<br />POUR TIME</TableCell>
                <TableCell>AVG<br />ARRV AGE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={isXs ? classes.controlled : ""}>
                <TableCell>{data?.mixcode}</TableCell>
                <TableCell>{convertM3(data?.pouredM3, metric, false)}</TableCell>
                <TableCell>{`${convertM3(data?.pourRateM3PerHour, metric, false)}/hr`}</TableCell>
                <TableCell>{`${avgWait} min`}</TableCell>
                {/* <TableCell>{avgPourTime ? `${avgPourTime} min` : "--"}</TableCell> */}
                <TableCell>--</TableCell>
                <TableCell>{avgArrivalAge ? `${avgArrivalAge} min` : "--"}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow className={isXs ? classes.controlled : ""}>
                <TableCell>AVG<br />SLUMP</TableCell>
                <TableCell>TARGET<br />SLUMP</TableCell>
                <TableCell>AVG<br />REVS</TableCell>
                <TableCell>AVG<br />TEMP</TableCell>
                <TableCell>AVG H2O<br />ADDED</TableCell>
                <TableCell>AVG H2O<br />REMAINING</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={isXs ? classes.controlled : ""}>
                <TableCell>{convertMm(avgSlump, metric)}</TableCell>
                <TableCell>{convertMm(data?.targetSlumpMm, metric)}</TableCell>
                <TableCell>{avgRevs}</TableCell>
                <TableCell>{convertDegC(data?.pourTemperatureAverageC, metric)}</TableCell>
                <TableCell>{convertL(avgWaterAdded, metric)}</TableCell>
                <TableCell>{convertL(avgWaterRemaining, metric)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      { tickets && tickets.length > 0 && <MapSection tickets={tickets} /> }
      <LowerTable tickets={tickets} handleTicket={handleTicket} />
      <Button
          color="primary"
          size="large"
          variant="contained"
          type="submit"
          onClick={() => {
            setNeedsRefresh(true);
          }}
          className={classes.info}
        >
          REFRESH
        </Button>
    </>
  )
};