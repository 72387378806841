import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import UserContext from "./UserContext";
import WebApp from "./WebApp";

export default () => {
    const { email } = useContext(UserContext);
    const auth = !!email;
    return (
        <Route render={props => {
            if (!auth) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }

            // authorized so return component
            return <WebApp />
        }} />
    );
};