import { useContext } from "react";
import Box from "@material-ui/core/Box";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import UserContext from "../UserContext";

const useStyles = makeStyles(
  ({ spacing }) => {
    return {
      radioGroup: {
        padding: spacing(2),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
      },
    };
  },
);

export default () => {

  const classes = useStyles();
  const {
    metric,
    updateMetric
  } = useContext(UserContext);

  const setMetric = (e) => {
    updateMetric(e.target.value);
  };

  return (
    <Box>
      <RadioGroup value={metric} className={classes.radioGroup} onChange={setMetric}>
        <FormControlLabel
          value="1"
          control={<Radio color='primary' />}
          label="Metric" />
        <FormControlLabel
          value="2"
          control={<Radio color='primary' />}
          label="US/Imperial" />
      </RadioGroup>
    </Box>
  );
}